import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Jumbotron, Row, Spinner } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

const Landing = ({ auth, play, ...props }) => {

    const agent = auth.agent;
    // console.log(auth)
    const history = useHistory();
    useEffect(() => {
        if (!auth.isLoading && auth.isAuthenticated && play.version && history.location.pathname === '/') {
            // console.log(history)
            history.replace('/play');
        }
    }, [auth.isLoading, auth.isAuthenticated, play.version, history])

    return <Row>
        <Col lg="6" md="8" className="mx-auto">
            {typeof window !== 'undefined'
                && <Jumbotron className="bg-gradient-tgl-primary">
                    {
                        auth.isTokenExpired
                        && <h1 className="text-center text-gold">
                            Sesi Anda sudah berakhir
                            {agent?.url && <div>Kembali ke <a href={agent?.url}>{agent?.name}</a></div>}
                        </h1>
                        || (
                            !auth.isAuthenticated
                            && <h1 className="text-center text-gold">
                                Link ini tidak bisa diakses secara langsung
                            </h1>
                            || <h1 className="text-center text-gold">
                                <div>Mohon menunggu</div>
                                <Spinner color="gold" size="lg"></Spinner>
                            </h1>
                        )
                    }
                </Jumbotron>
            }
        </Col>
    </Row>
};

export default connect(
    (state) => ({ auth: state.auth, play: state.play }),
    {}
)(Landing);
